<template>
  <div class="form-group file-upload-wrapper">
    <div class="input-group input-group-file-upload">
      <div v-if="!file">
        <label class="file-upload-label">
          <slot name="title">File Upload</slot>
        </label>
        <vuestic-file-upload
          type="single"
          :dropzone="true"
          :fileTypes="types"
          v-model="fileUploadList"
        />
      </div>
      <div class="file-upload-preview row" v-else>
        <div class="col-md-12 text-center">
          <audio-player v-if="type === 'Audio'"  :src="file.path" />
          <span v-else class="font-weight-bold text-primary">{{ file.name }}</span>
        </div>
        <div class="col-md-12 actions mt-3 text-center">
          <button class="btn btn-primary btn-sm align-middle" @click.prevent="deleteFile">Delete</button>
        </div>
      </div>
    </div>

    <div class="align-self-center overlay-dim" v-if="spinner.active">
      <atom-spinner :animation-duration="1000" :size="120" :color="spinner.color" />
    </div>
  </div>
</template>

<script>
  import VuesticFileUpload from "../../vuestic-theme/vuestic-components/vuestic-file-upload/VuesticFileUpload";

  export default {
    components: {
      VuesticFileUpload,
    },
    name: 'file-upload',
    props: {
      file: {
        type: [ Object, File],
        default: null
      },
      types: {
        type: String,
        default: '.mp3, .wav'
      },
      type: {
        type: String,
        default: 'Audio'
      }
    },
    mounted() {
    },
    data() {
      return {
        fileUploadList: [],
        fileUploadLoading: false,
        spinner: {
          active: false,
          color: '#ffffff'
        },
      }
    },
    watch: {
      fileUploadList (n, o) {
        this.uploadFile();
      },
    },
    methods: {
      uploadFile() {
        const file = (this.fileUploadList.length) ? this.fileUploadList[this.fileUploadList.length - 1] : null;
        if (!(file instanceof File)) return;

        this.spinner.active = true;

        let event = {};
        let promise = Promise.resolve();

        event.file = file;
        event.waitUntil = p => promise = p;
        this.$emit('upload', event);

        promise.then((result) => {
          this.fileUploadList = [];
          this.spinner.active = false;
        })
      },

      deleteFile() {
        if (!this.file) return;

        this.spinner.active = true;

        let event = {};
        let promise = Promise.resolve();

        event.waitUntil = p => promise = p;
        this.$emit('delete', event);

        promise.then((result) => {
          this.fileUploadList = [];
          this.spinner.active = false;
        })
      },
    }
  }
</script>

<style lang="scss">
  .file-upload-wrapper {
    .ar-player {
      margin: auto;
    }
  }
</style>
<style lang="scss" scoped>
  .vuestic-file-upload-field--dropzone {
    padding: 0 .7rem !important;
    height: 2.4rem !important;
  }

  .input-group-company-logo {
    .vuestic-file-upload-single {
      height: 60px;

      button {
        display: none;
      }
    }
  }

  .file-upload-preview {
    img {
      max-width: 100%;
      max-height: 100px;
      margin: 5px auto;
    }
    .actions {
      text-align: right;
    }
  }

  .overlay-dim {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;

    position: absolute;
    top:0;
    left:0;
    bottom: 0;
    right:0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .file-upload-label {
    display: block;
    text-align: left;
    clear: both;
    font-size: 0.6rem;
    color: #333;
    font-weight: 600;
    text-transform: uppercase;
    top: -0.6rem;
    left: 0;
  }
</style>
