<template>
  <div class="row">
    <div class="col-md-12">
      <label v-if="label" class="input-label">{{ label }}</label>
      <vuestic-tabs
        :names="tabs"
        v-model="tabSelected"
        class="pt-2"
      >
        <span :slot="tabs[0]">
          <file-upload
            @upload="onUploadVoice"
            @delete="onDeleteVoice"
            :show-upload-button="false"
            :file="voiceFile"
          >
            <span slot="title">Audio File ( MAX 1MB )</span>
          </file-upload>
        </span>
        <span :slot="tabs[1]">
          <div class="row record-wrapper">
            <div v-if="processing" class="align-self-center overlay-dim">
              <atom-spinner :animation-duration="1000" :size="120" color="#fff" />
            </div>
            <div class="col-md-12 mb-4">
              <p class="font-weight-bold">To record a voice mail, allow the browser to access your microphone and then click on the microphone. Click the stop button when you are done.</p>
            </div>
            <div class="col-md-12">
              <div v-if="!isPermissionDenied" class="recorder-container">
                <button class="recorder-action" type="button" @click="handleClickRecording" :class="{ recording: isRecording }">
                  <img v-if="!isRecording" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIEdlbmVyYXRvcjogU1ZHIFJlcG8gTWl4ZXIgVG9vbHMgLS0+DQo8c3ZnIGZpbGw9IiMwMDAwMDAiIGhlaWdodD0iODAwcHgiIHdpZHRoPSI4MDBweCIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgDQoJIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGcgaWQ9Ik1pY3JvcGhvbmVfMV8iPg0KCTxwYXRoIGQ9Ik0zMiw0MGM2LjA2NTM5OTIsMCwxMS01LjA2NTM5OTIsMTEtMTEuMjkxOTk5OFYxMS4yOTE5OTk4QzQzLDUuMDY1NDk5OCwzOC4wNjUzOTkyLDAsMzIsMFMyMSw1LjA2NTQ5OTgsMjEsMTEuMjkxOTk5OA0KCQl2MTcuNDE2MDAwNEMyMSwzNC45MzQ2MDA4LDI1LjkzNDYwMDgsNDAsMzIsNDB6IE0yMywxMS4yOTE5OTk4QzIzLDYuMTY4NDk5OSwyNy4wMzc1OTk2LDIsMzIsMnM5LDQuMTY4NDk5OSw5LDkuMjkxOTk5OA0KCQl2MTcuNDE2MDAwNEM0MSwzMy44MzE2MDAyLDM2Ljk2MjM5ODUsMzgsMzIsMzhzLTktNC4xNjgzOTk4LTktOS4yOTE5OTk4VjExLjI5MTk5OTh6Ii8+DQoJPHBhdGggZD0iTTUwLDI1Yy0wLjU1MjIwNDEsMC0xLDAuNDQ3Nzk5Ny0xLDF2My42MzMzMDA4QzQ5LDM5LjIwOTQ5OTQsNDEuMzc0MDAwNSw0NywzMiw0N3MtMTctNy43OTA1MDA2LTE3LTE3LjM2NjY5OTJWMjYNCgkJYzAtMC41NTIyMDAzLTAuNDQ3Nzk5Ny0xLTEtMXMtMSwwLjQ0Nzc5OTctMSwxdjMuNjMzMzAwOGMwLDEwLjMzNDgwMDcsNy45ODk0MDA5LDE4Ljc4MDA5OCwxOCwxOS4zMTQ5OTg2VjYySDIxDQoJCWMtMC41NTIyMDAzLDAtMSwwLjQ0Nzc5OTctMSwxYzAsMC41NTIyOTk1LDAuNDQ3Nzk5NywxLDEsMWgyMmMwLjU1MjE5NjUsMCwxLTAuNDQ3NzAwNSwxLTFjMC0wLjU1MjIwMDMtMC40NDc4MDM1LTEtMS0xSDMzDQoJCVY0OC45NDgyOTk0YzEwLjAxMDU5NzItMC41MzQ5MDA3LDE4LTguOTgwMTk3OSwxOC0xOS4zMTQ5OTg2VjI2QzUxLDI1LjQ0Nzc5OTcsNTAuNTUyMTk2NSwyNSw1MCwyNXoiLz4NCjwvZz4NCjwvc3ZnPg==">
                  <img v-else src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyBmaWxsPSIjMDAwMDAwIiB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxyZWN0IHdpZHRoPSIyNTYiIGhlaWdodD0iMjU2IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyOCAxMjgpIi8+PC9zdmc+" >
                </button>
                <p class="note"> {{ duration }}s / {{ limit * 60 }}s</p>
                <audio ref="audio" v-show="audioURL" :src="audioURL" controls></audio>
              </div>
              <div v-else style="text-align: center;color: red;font-size: 16px;margin: 20px;">You are not allowed to use your microphone, Please allow microphone and try again.</div>
            </div>
          </div>
        </span>
      </vuestic-tabs> 
    </div>
  </div>
</template>

<script>
import FileUpload from "./FileUpload";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios'

export default {
  components: { FileUpload, Loading },

  props: {
    label: {
      type: String,
      required: false,
      default: 'Voice Recording',
    },
    value: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      tabs: ["Upload File", "Create recording"],
      tabSelected: "Upload File",
      voiceFile: null,
      recordFile: null,
      isLoading: false,
      mediaChunks: [],
      mediaRecorder: null,
      mediaTimeout: null,
      isRecording: false,
      isPermissionDenied: false,
      audioURL: null,
      limit: process.env.VUE_APP_VOICE_LIMIT_MIN,
      duration: 0,
      mediaInterval: null,
      processing: false,
    }
  },

  mounted() {
    this.voiceFile = this.value
    this.$eventBus.$on('remove-record', () => {
      this.recordFile = null
    })
  },

  computed: {
  },

  watch: {
    'voiceFile': {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue)
        {
          this.emitData();
        }
      }
    },
    'recordFile': {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue)
        {
          this.emitData();
        }
      }
    },
    'tabSelected': {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.emitData();
        }

        if (newValue === this.tabs[1]) {
          this.initMediaRecorder()
        }
      }
    },
  },

  methods: {
    initMediaRecorder() {
      navigator.mediaDevices
        .getUserMedia({
            audio: true,
        })
        .then((stream) => {
            this.microphoneEnabled = true
            this.mediaRecorder = new MediaRecorder(stream)
            this.mediaChunks = []

            this.mediaRecorder.ondataavailable = (e) => {
                this.mediaChunks.push(e.data);
            }

            const blob = new Blob(this.mediaChunks, { type: "audio/mp3; codecs=opus" })

            this.mediaRecorder.onstop = (e) => {
                this.handleMediaRecordingStop()
            }

        
        })
        .catch(() => {
          this.isPermissionDenied = true
        })
    },

    handleClickRecording() {
      if (this.isRecording) {
        this.mediaRecorder.stop()
      } else {
        this.isRecording = true
        this.mediaRecorder.start()
        this.duration = 0
        this.mediaTimeout = setTimeout(() => {
          this.mediaRecorder.stop()
        }, this.limit * 60 * 1000)

        this.mediaInterval = setInterval(() => {
          this.duration += 1
        }, 1000)
      }
    },

    handleMediaRecordingStop() {
      this.isRecording = false
      clearInterval(this.mediaInterval)
      clearTimeout(this.mediaTimeout)

      const blob = new Blob(this.mediaChunks, { type: "audio/mp3; codecs=opus" })
      this.audioURL = window.URL.createObjectURL(blob)

      this.$refs.audio.currentTime = 999
      this.$refs.audio.addEventListener('canplay', () => {
        this.$refs.audio.play()
      })
      this.processing = true;
      let postData = new FormData()
      postData.append('file', blob)
      postData.append('file_type', 'recording')

      axios.post(`/v1/files`, postData)
        .then(({ data }) => {
          if (data.success) {
            this.voiceFile = data.file
            this.$emit('input', data.file)
          }
          this.processing = false;
        }).catch((e) => {
          this.processing = false;
        })
    },

    emitData() {
      if (this.tabSelected == this.tabs[0])
      {
        this.$emit('input', this.voiceFile)
      } else if (this.tabSelected == this.tabs[1]) {
      }
    },
    onUploadVoice(event) {
      event.waitUntil(new Promise((resolve, reject) => {
        let postData = new FormData();
        postData.append('file', event.file);
        axios.post(`/v1/files`, postData)
          .then(({data}) => {
            if (data.success) {
              this.voiceFile = data.file
              resolve(data.file);
            } else {
              reject();
            }
          });
      }))
    },

    onDeleteVoice(event) {
      event.waitUntil(new Promise((resolve, reject) => {
        const param = {
          filename: this.voiceFile.name
        }
        axios.delete(`/v1/files`, param)
          .then(({data}) => {
            if (data.success) {
              this.voiceFile = null
              resolve();
            } else {
              reject();
            }
          }).catch((e) => {
            reject(e);
          });

      }))
    },
  }
}
</script>

<style scoped lang="scss">
@keyframes beat{
	to { transform: scale(1.2); }
}

.record-wrapper {
  position: relative;
}
.recorder-container {
  text-align: center;
  margin-top: 10px;

  .note {
    margin-top: 15px;
  }
}
.recorder-action {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 20px;
  background: #fb6a6a;
  border: none;
  cursor: pointer;
  &:hover {
      opacity: 0.5;
  }
  &.recording {
    animation: beat .25s infinite alternate;
    transform-origin: center;
    background: #34d334;
  }
  img {
    max-width: 100%;
    
  }
}
</style>