<template>
<div class="action-block">
  <div class="common-action dark-gray" @click="showModal">
    <i :class="block.icon"></i> {{  block.name  }}
  </div>

  <vuestic-modal
    :isOpen="isShowModal"
    @cancel="isShowModal = false"
    :okShown="false"
    :cancelShown="false"
    :force="true"
    :fixed="true"
    cancelClass="btn btn-danger"
  >
    <span slot="title" class="text-primary font-weight-bold">{{ block.description }}</span>
    <ValidationObserver 
      v-slot="{ handleSubmit, invalid, dirty }"
      ref="sendMessageActionForm"
    >
      <form @submit.prevent="handleSubmit(save)" class="sendMessageActionForm mt-4">
        <div class="row mb-4">
          <div class="col-12 mb-4">
            <text-input v-model="block.name"></text-input>
          </div>
          <div class="col-md-12">
            <vuestic-switch
              v-model="block.options.process_now"
              :offcolor="false"
              style="width: 400px"
            >
              <span slot="trueTitle">Send Now</span>
              <span slot="falseTitle">Send Later</span>
            </vuestic-switch>
          </div>
        </div>
        <div v-if="!block.options.process_now" class="row">
          <div class="col-md-12">
            <label class="input-label">TimeZone</label>
            <multiselect
              v-model="block.options.timezone"
              :options="timezones"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Select Timezone"
              label="label"
              track-by="label"
              :preselect-first="true"
              :show-labels="false"
            >
            </multiselect>
          </div>
          <div class="col-md-12 mt-4">
            <div class="form-group">
              <div class="input-group">
                <label class="control-label">Time</label>
                <vuestic-date-picker
                  id="scheduled_at_time_picker"
                  v-model="block.options.send_at"
                  :config="timePickerConfig"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <VoiceRecording
              label=""
              v-model="block.options.file"
            />
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-primary" :disabled="invalid || processing || !hasFile" style="min-width: 80px;">
              <span>Save</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-modal>
</div>
</template>

<script>
import VoiceRecording from "../../../../common/VoiceRecording"

export default {
  components: { VoiceRecording, },

  props: {
    block: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      processing: false,
      isShowModal: false,
      timePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "G:i K",
      },
      isLoading: false,
    }
  },

  mounted() {
    if (this.block.isNew) {
      this.block.isNew = false
      this.showModal()
    }

    if (this.block.options.file)
    {
      this.voiceFile = this.block.options.file
    }

    this.$eventBus.$on('remove-record', () => {
      this.recordFile = null
    })
  },

  computed: {
    timezones() {
      return this.$store.getters["timeZones"];
    },

    hasFile() {
      return this.block && this.block.options && this.block.options.file
    },
  },

  methods: {
    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    },
  }
}
</script>

<style lang="scss">
  .action-block {
    .tab-content {
      padding-top: 20px;
      padding-bottom: 0px;
    }
  }
</style>